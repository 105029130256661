<template>
  <v-card>
    <v-progress-linear
      :active="loading"
      color="blue"
      height="4"
      indeterminate
    />
    <v-card-text>
      <Gallery
        :data="images"
        @repeatFetching="fetchImage(...arguments, true)"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import Gallery from '@/components/Gallery/Gallery';
import notification from "@/notification/notify";

export default {
  name: 'ExposureStep',
  components: {Gallery},
  props: {
    visitId: {
      type: [Number, String],
      required: true
    }
  },
  data: () => ({
    images: [],
    loading: false
  }),
  async beforeMount() {
    await this.fetchData()
    await this.fetchImages()
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true
        const {data} = await axios.get(`sk_visits/${this.visitId}/step/exposure`)
        this.images = data.data.map(img => ({...img, src: 'loading', title: img.filename}))
      } catch (e) {
        notification.notify(
          this.$t('notify.error'),
          this.$t('system.can_not_load'),
          'error');
      } finally {
        this.loading = false
      }
    },
    async fetchImages() {
      for (let i = 0; i < this.images.length; i++) {
        await this.fetchImage(this.images[i].id)
      }
    },
    async fetchImage(imageId, setDefaultValue = false) {
      try {
        if (setDefaultValue) {
          const candidate = this.images.find(item => item.id === imageId)
          candidate.src = 'loading'
        }
        const {data} = await axios.get(`sk_visits/${this.visitId}/step/exposure/${imageId}`, {
          responseType: 'blob',
        })
        let reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => {
          const candidate = this.images.find(item => item.id === imageId)
          candidate.src = reader.result
        };
      } catch (e) {
        const candidate = this.images.find(item => item.id === imageId)
        candidate.src = ''
      }
    },
  }
}
</script>

<style scoped>

</style>
